<template>
    <div class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
        <div class="app-auth-background">

            <div class="policy-container">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <br/>
                            <h4 class="text-center" style="text-decoration: underline;">Password Policy Enforcement</h4>
                            <div class="card-body">
                                <p>As part of our commitment to keeping your data secure, we enforce certain restrictions on the passwords you can use for your account. You can see these restrictions below.</p>

                                <p>When you enter your new password you will see restrictions start to turn green as you meet them. If any requirement is red the password cannot be used.</p>
                            </div>
                        </div>

                        <div v-for="policy in policies">
                            <div class="card m-t-sm">
                                <div class="card-header" style="font-weight: bold;">
                                    {{ policy.name }}
                                </div>
                                <div class="card-body">
                                    <ul>
                                        <li :class="{'text-success': passwordsMatch, 'text-danger': !passwordsMatch}">Passwords must match</li>
                                        <PasswordRule v-for="rule in policy.rulesets.password" @satisfied="(result) => handleRuleChange(rule, result)" :key="'policy-' + rule.name" :value="{
                                            value: resetForm.password
                                        }" :rule="rule"></PasswordRule>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="app-auth-container">

            <Form @submit="(values, {resetForm}) => resetPassword(values, resetForm)" :validation-schema="schema">
              <div class="auth-credentials m-b-xxl">

                <div class="alert alert-custom alert-indicator-left indicator-info" role="alert">
                    <div class="alert-content">
                        <span class="alert-title">Important!</span>
                        <span class="alert-text">Please make sure to pick a password which meets the requirements outlined in your password policy.</span>
                    </div>
                </div>
                  
                <div class="form-group col-6">
                    <label for="resetPassword" class="form-label m-t-md">{{$t('login.password')}}</label>
                    <Field type="password" v-model="resetForm.password" name="password" class="form-control" id="resetPassword" aria-describedby="resetPassword" placeholder="Password"/>
                    <ErrorMessage name="password" class="error-feedback" />
                </div>

                <div class="form-group col-6">
                    <label for="resetPasswordConfirm" class="form-label m-t-md">{{$t('reset.confirm_password')}}</label>
                    <Field type="password" v-model="resetForm.confirm_password" name="confirm_password" class="form-control" id="resetPasswordConfirm" aria-describedby="resetPasswordConfirm" placeholder="Confirm password"/>
                    <ErrorMessage name="confirm_password" class="error-feedback" />
                </div>

                  <p v-show="message" class="text-danger p-v-sm"> {{$t('reset.failed')}}  </p>
              </div>
              <div class="auth-submit">
                  <button :disabled="!rulesPassed || loading" type="submit" class="btn btn-primary">
                    <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                    <span>{{$t('reset.reset')}}</span>
                  </button>
                  <!-- <a href="javascript:void(0);" class="auth-forgot-password float-end">Forgot password?</a> -->
              </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import PasswordRule from './PasswordRule.vue';
import axios from 'axios';

export default {
    name: 'Reset',
    components: {
        Form,
        Field,
        ErrorMessage,
        PasswordRule
    },
    data(){
        const schema = yup.object().shape({
            password: yup.string().required(this.$t('login.errors.password')),
            confirm_password: yup.string().required(this.$t('reset.confirm_error')),
        });
        return {
            schema,
            resetForm: {
                password: '',
                confirm_password: ''
            },
            resetToken: null,
            policies: [
                {
                    "name": "VARS Default Policy",
                    "weight": 0,
                    "rulesets": {
                    "user": [],
                    "password": [
                        {
                        "field": "value",
                        "operator": "length",
                        "value": {
                            "min": 8,
                            "max": 20
                        },
                        "error": "Password must be between 8 and 20 characters",
                        "action": "return_error"
                        },
                        {
                        "field": "value",
                        "operator": "contains",
                        "value": {
                            "expressions": [
                            "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                            ]
                        },
                        "error": "Password must contain at least one uppercase letter",
                        "action": "return_error"
                        },
                        {
                        "field": "value",
                        "operator": "contains",
                        "value": {
                            "expressions": [
                            "abcdefghijklmnopqrstuvwxyz"
                            ]
                        },
                        "error": "Password must contain at least one lowercase letter",
                        "action": "return_error"
                        },
                        {
                        "field": "value",
                        "operator": "contains",
                        "value": {
                            "expressions": [
                            "0123456789"
                            ]
                        },
                        "error": "Password must contain at least one number",
                        "action": "return_error"
                        },
                        {
                        "field": "age",
                        "operator": "lessThan",
                        "value": 365,
                        "error": "Password must be changed every 365 days",
                        "action": "reset_password"
                        },
                        {
                        "field": "age",
                        "operator": "greaterThan",
                        "value": 1,
                        "error": "Password can only be changed once every 24 hours",
                        "action": "return_error"
                        },
                        {
                        "field": "failedLoginAttempts",
                        "operator": "lessThan",
                        "value": 6,
                        "error": "Account is locked after 5 failed login attempts",
                        "action": "return_error"
                        }
                    ]
                    }
                }
            ],
            loading: false,
            message: false
        }
    },
    mounted(){
        this.resetToken = this.$route.query.token;
        if(this.resetToken === null || this.resetToken === undefined){
            this.$error("No reset token provided");
            this.$router.push({name: 'Login'});
            return;
        }
        if((this.resetToken || "").length < 1){
            this.$error("Reset token is empty");
            this.$router.push({name: 'Login'});
            return;
        }
        axios.get(`https://api.varsanpr.com/api/users/policies?token=${this.resetToken}`)
            .then(response => {
                this.policies = [response.data.policies];
            })
            .catch(error => {
                this.$error("Failed to get password policies", error);
            });
    },
    methods: {
        handleRuleChange(rule, result){
            console.log(rule, result)
            rule.passed = result;
        },
        resetPassword(values, reset){
            this.loading = true;
            axios.patch(`https://api.varsanpr.com/api/users/reset`, {
                token: this.resetToken,
                password: values.password,
                confirm: values.confirm_password
            })
                .then(response => {
                    this.$success("Password reset successfully");
                    this.$router.push({name: 'Login'});
                })
                .catch(error => {
                    this.$error("Failed to reset password", error);
                    this.message = true;
                    reset();
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    computed: {
        night(){
            return this.$store.state.auth.status.dark;
        },
        rulesPassed: function(){
            return this.policies[0].rulesets.password.filter(rule => !rule.passed).length < 1 && this.passwordsMatch;
        },
        passwordsMatch: function(){
            return this.resetForm.password === this.resetForm.confirm_password && this.resetForm.password.length > 0;
        }
    }
}
</script>

<style scoped>
    .app-auth-background {
        height: 100vh;
        background: none;
    }

    .policy-container {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
</style>