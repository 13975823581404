<template>
    <li :class="{'text-success': isSatisfied, 'text-danger': !isSatisfied}">{{rule.error}}</li>
</template>

<script>
export default {
    name: "PasswordRule",
    props: {
        value: {
            type: [Number, Object, String]
        },
        rule: Object
    },
    computed: {
        isSatisfied(){
            if(!['value'].includes(this.rule.field)){
                this.$emit('satisfied', true);
                return true;
            }
            let field = this.rule.field.split('.');
            function run(object, rule){
                let currentField = field.shift();

                if(field.length === 0){

                    switch(rule.operator){
                        case 'length':
                            return object[currentField].length >= rule.value.min && object[currentField].length <= rule.value.max;
                        case 'contains':
                            return rule.value.expressions.some((expression) => {
                                return expression.split('').some((character) => {
                                    return object[currentField].includes(character);
                                });
                            });
                        case 'excludes':
                            return !rule.value.expressions.some((expression) => {
                                return expression.split('').some((character) => {
                                    return object[currentField].includes(character);
                                });
                            });
                        case 'equals':
                            return object[currentField] === rule.value;
                        case 'notEquals':
                            return object[currentField] !== rule.value;
                        case 'greaterThan':
                            return object[currentField] > rule.value;
                        case 'lessThan':
                            return object[currentField] < rule.value;
                        case 'greaterThanOrEquals':
                            return object[currentField] >= rule.value;
                        case 'lessThanOrEquals':
                            return object[currentField] <= rule.value;
                    }

                }else{

                    return run(object[currentField], rule);

                }
            }

            let result = run(this.value, this.rule);
            this.$emit('satisfied', result);
            return result;
        }
    }
}
</script>